<!-- =========================================================================================
    File Name: ECommerceShop.vue
    Description: eCommerce Shop Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="#product_page">
    <div id="algolia-instant-search-demo">

      <!-- AIS CONFIG -->

      <div class="algolia-header mb-4">
        <div class="flex md:items-end items-center justify-between flex-wrap">
          <!-- TOGGLE SIDEBAR BUTTON -->
          <feather-icon
            class="inline-flex lg:hidden cursor-pointer mr-4"
            icon="MenuIcon"
            @click.stop="toggleFilterSidebar" />

          <p class="lg:inline-flex hidden font-semibold algolia-filters-label">Filters</p>

          <div class="flex justify-between items-end flex-grow">
            <!-- Stats -->

            <p class="font-semibold md:block hidden">
              {{ refined.length }} results found
            </p>

            <div class="flex flex-wrap">

              <!-- SORTING -->
              <v-select style="width: 200px"
                        class="mr-4 vs-select-no-border"
                        v-model="sortSelected" :options="sortOptions" @input="refine" :clearable="false"></v-select>

            </div>
          </div>
          <p class="font-semibold block md:hidden">
            {{ refined.length }} results found
          </p>
        </div>
      </div>

      <div id="algolia-content-container" class="relative clearfix">
        <vs-sidebar
          class="items-no-padding vs-sidebar-rounded background-absolute"
          parent="#algolia-content-container"
          :click-not-close="clickNotClose"
          :hidden-background="clickNotClose"
          v-model="isFilterSidebarActive">

          <div class="p-6 filter-container">

              <vs-collapse>
                <vs-collapse-item>
                  <div slot="header">
                    PRICE RANGE
                  </div>
                  <ul>
                    <li @click="refine()">
                      <vs-radio v-model="criteria.price" vs-value="0">ALL</vs-radio>
                    </li>
                    <li @click="refine()">
                      <vs-radio v-model="criteria.price" vs-value="1">< 100</vs-radio>
                    </li>
                    <li @click="refine()">
                      <vs-radio v-model="criteria.price" vs-value="2">100-1000</vs-radio>
                    </li>
                    <li @click="refine()">
                      <vs-radio v-model="criteria.price" vs-value="3">1000-2000</vs-radio>
                    </li>
                    <li @click="refine()">
                      <vs-radio v-model="criteria.price" vs-value="4">> 2000</vs-radio>
                    </li>
                  </ul>
                </vs-collapse-item>
                <vs-collapse-item>
                <div slot="header">
                  CATEGORIES
                </div>
                <ul>
                  <li v-for="item in $store.state.categoryNested" :key="item.id" class="items-center cursor-pointer py-1">
                    <vs-checkbox @input="refine()" v-model="criteria.categories" :vs-value="item.slug">{{item.name}}</vs-checkbox>
                    <ul v-if="item.sub_categories.length">
                      <li class="pl-3 items-center cursor-pointer py-1" v-for="sc in item.sub_categories" :key="sc.id">
                        <vs-checkbox @input="refine()" v-model="criteria.categories" :vs-value="sc.slug">{{sc.name}}</vs-checkbox>
                        <ul v-if="sc.sub_categories.length">
                          <li class="pl-3 items-center cursor-pointer py-1" v-for="ssc in sc.sub_categories" :key="ssc.id"><vs-checkbox @input="refine()" v-model="criteria.categories" :vs-value="ssc.slug">{{ssc.name}}</vs-checkbox></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </vs-collapse-item>
                <vs-collapse-item v-if="filter_attr.length" v-for="(attr,i) in filter_attr" :key="i">
                  <div slot="header">
                    {{attr.name}}
                  </div>
                  <ul>
                    <li v-for="item in attr.values" :key="item.id" class="items-center cursor-pointer py-1">
                      <vs-checkbox @input="refine_attr" v-model="criteria.attr" :vs-value="item.id">{{item.value}}</vs-checkbox>
                    </li>
                  </ul>
                </vs-collapse-item>
              </vs-collapse>

            <!--<vs-divider />-->
            <!--<VuePerfectScrollbar v-if="$store.state.brands.length" ref="mainSidebarPs" style="max-height: 150px" class="scroll-area&#45;&#45;main-sidebar pt-2" :settings="settings">-->
              <!--<h6 class="font-bold mb-4">Brand</h6>-->
              <!--<div>-->
                <!--<ul>-->
                  <!--<li v-for="item in $store.state.brands" :key="item.id" class="flex items-center cursor-pointer py-1">-->
                    <!--<vs-checkbox @input="refine()" v-model="criteria.brands" :vs-value="item.slug">{{item.name}}</vs-checkbox>-->
                  <!--</li>-->
                <!--</ul>-->
              <!--</div>-->
            <!--</VuePerfectScrollbar>-->
            <!--&lt;!&ndash; Brands &ndash;&gt;-->

            <!--<vs-divider />-->


            <vs-button @click.prevent="clearFilter">CLEAR ALL FILTERS</vs-button>

          </div>
        </vs-sidebar>

        <!-- RIGHT COL -->
        <div :class="{'sidebar-spacer-with-margin': clickNotClose}">

          <!-- SEARCH BAR -->

          <div >
            <div class="relative mb-8">

              <!-- SEARCH INPUT -->
              <vs-input class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg" placeholder="Search here" v-model="criteria.query"  @input="refine()" @keyup.esc="criteria.query='';refine()" size="large" />

              <!-- SEARCH ICON -->
              <div slot="submit-icon" class="absolute top-0 right-0 py-4 px-6" v-show="!criteria.query">
                <feather-icon icon="SearchIcon" svgClasses="h-6 w-6" />
              </div>

              <!-- CLEAR INPUT ICON -->
              <div slot="reset-icon" class="absolute top-0 right-0 py-4 px-6" v-show="criteria.query">
                <feather-icon icon="XIcon" svgClasses="h-6 w-6 cursor-pointer" @click="criteria.query='';refine()" />
              </div>
            </div>
          </div>

          <!-- SEARCH RESULT -->

          <div>
            <!-- GRID VIEW -->
            <template>
              <div class="items-grid-view vx-row match-height">
                <div class="vx-col lg:w-1/6 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="item in refined.slice(0, (18*currentx))" :key="item.id">

                  <item-grid-view :item="item"></item-grid-view>

                </div>
              </div>
              <infinite-loading ref="infiniter" @infinite="loadMore">
              </infinite-loading>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import Fuse from 'fuse.js';
  import vSelect from 'vue-select'
  import InfiniteLoading from 'vue-infinite-loading';
  export default {
    components: {
      ItemGridView: () => import("./components/ItemGridView.vue"),
      'v-select': vSelect,
      VuePerfectScrollbar,
      InfiniteLoading,
    },
    data() {
      return {
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: 1,
          swipeEasing: true
        },
        refined_ini:[],
        refined:[],
        sortOptions: [
          {id: 1, label: 'Best match'},
          {id: 2, label: 'Price high to low'},
          {id: 3, label: 'Price low to high'},
          {id: 4, label: 'Name asc'},
          {id: 5, label: 'Name desc'},
        ],
        criteria:{
          query:this.$route.query.search?this.$route.query.search:'',
          price:'',
          categories:this.$route.query.category?[this.$route.query.category]:[],
          brands:[],
          attr:[],
        },
        sortSelected: {id: 1, label: 'Best match'},
        currentx:1,
        // Filter Sidebar
        isFilterSidebarActive: true,
        clickNotClose: true,
        windowWidth: window.innerWidth,
        currentItemView: 'item-grid-view',
        numericItems: [
          { label: 'All' },
          { start:0, end: 100 },
          { start: 100, end: 1000 },
          { start: 1000, end: 2000 },
          { start:2000 },
        ],
      }
    },
    computed: {
      filter_attr(){
        var attr = [];
        this.refined_ini.forEach(function (item) {
          item.attributes.forEach(function (si) {
              if (si.attribute.is_filterable && attr.indexOf(si.attribute_id) == -1)
                attr.push(si.attribute_id);
          })
        });
        var all_attr = this.$store.state.attributesFilterable.filter(function (item) {
          return attr.indexOf(item.id) != -1
        })
        return all_attr;
      },
      validNumber(){
        return (this.phone && this.phone.length == 11);
      },
      totalPage(){
       return Math.ceil(this.refined.length/18);
      }
    },
    watch:{
      '$route'(to, from){
        this.criteria.categories = to.query.category?[to.query.category]:[];
        this.criteria.query = to.query.search ? to.query.search:'';
        this.refine();
      },
      '$store.state.products'(val) {
        this.$vs.loading.close();
        this.refine();
      }
    },
    methods: {
      clearFilter(){
        this.criteria.price = '';
        this.criteria.categories = [];
        this.criteria.brands = [];
        this.criteria.attr = [];
        this.criteria.query='';
        this.refine();
      },
      sort_refined(a, b){
        if (this.sortSelected.id == 2) {
          return parseInt(a.price.amount) >= parseInt(b.price.amount)? -1:1;
        }
        if (this.sortSelected.id == 3){
          return parseInt(a.price.amount) >= parseInt(b.price.amount)? 1:-1;
        }
        if (this.sortSelected.id == 4){
          return a.name > b.name? 1:-1;
        }
        if (this.sortSelected.id == 5){
          return a.name > b.name? -1:1;
        }
      },

      refine(){
        var options = {
          shouldSort: true,
          threshold: 0.3,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 3,
          keys: [
            "name",
            "slug",
            "sku",
            "description",
            "short_description",
          ]
        };
        var product= this.$store.state.products;
        var fuse = new Fuse(product, options); // "list" is the item array
        if (this.criteria.query){
          product = fuse.search(this.criteria.query);
        }
        // product = this.filterArray(product, this.criteria);
        var criteria = this.criteria;
        var range = criteria.price>0?this.numericItems[criteria.price]:'';
        product = product.filter(function (item) {
          // find category
          var category = true;
          if (criteria.categories.length) {
            var c_filter =  item.categories.find((c) => criteria.categories.includes(c.slug));
            if (!c_filter)
              category = false;
          }

          return (
            !criteria.brands.length || (item.brand && criteria.brands.includes(item.brand.slug)))
            && category
            && (!criteria.price || (!range ||(parseInt(item.price.amount)>=range.start &&(!range.end || parseInt(item.price.amount)<=range.end)))
            );
        });
        if (this.sortSelected.id !== 1){
          product.sort(this.sort_refined);
        }
        this.refined_ini = product;
        this.refine_attr();
      },
      refine_attr(){
        var criteria = this.criteria.attr;
        if (criteria.length>0){
          var filter = false;
          var rf = this.refined_ini.filter(function (item) {
            for (let i = 0; i < item.attributes.length; i++) {
              if (item.attributes[i].attribute.is_filterable){
                var values = item.attributes[i].values;
                var value = values.find(vl =>{
                  return criteria.includes(vl.attribute_value_id);
                })
                filter=value;
              }

            }
            return filter;
          });
          this.refined = rf;
        }else{
          this.refined = this.refined_ini;
        }
        this.$refs.infiniter.$emit('$InfiniteLoading:reset');
        this.currentx = 1;
      },
      handleWindowResize(event) {
        this.windowWidth = event.currentTarget.innerWidth
        this.setSidebarWidth()
      },
      setSidebarWidth() {
        if(this.windowWidth < 992) {
          this.isFilterSidebarActive = this.clickNotClose = false
        }else {
          this.isFilterSidebarActive = this.clickNotClose = true
        }
      },

      // GRID VIEW - ACTIONS
      toggleFilterSidebar() {
        if(this.clickNotClose) return
        this.isFilterSidebarActive = !this.isFilterSidebarActive
      },
      toggleItemInWishList(item) {
        this.$store.dispatch('eCommerce/toggleItemInWishList', item)
      },
      loadMore($state){
        if (this.currentx < this.totalPage){
          this.currentx++;
          setTimeout(() => {
            $state.loaded();
          }, 1000);
        }else{
          $state.complete();
        }
      }
    },
    created() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.handleWindowResize)
      })
      this.setSidebarWidth()
    },
    beforeDestroy: function() {
      window.removeEventListener('resize', this.handleWindowResize)
    },
    mounted(){
      this.refine();
    }
  }
</script>

<style lang="scss">
  #algolia-instant-search-demo {
    .algolia-header {
      .algolia-filters-label {
        width: calc(260px + 2.4rem);
      }
    }

    #algolia-content-container {

      .vs-sidebar {
        position: relative;
        float: left;
      }
    }

    .algolia-search-input-right-aligned-icon {
      padding: 1rem 1.5rem;
    }

    .algolia-price-slider {
      min-width: unset;
    }

    // .item-list-view {
    //     .algolia-result-img {

    //     }
    // }

    .item-view-primary-action-btn {
      color: #2c2c2c !important;
      background-color: #f6f6f6;
      min-width: 50%;
    }

    .item-view-secondary-action-btn {
      min-width: 50%;
    }
  }

  .theme-dark {
    #algolia-instant-search-demo {
      #algolia-content-container {
        .vs-sidebar {
          background-color: #10163a;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .vs-sidebar-rounded {
      .vs-sidebar { border-radius: .5rem; }
      .vs-sidebar--items {border-radius: .5rem; }
    }
  }

  @media (max-width: 992px) {
    #algolia-content-container {
      .vs-sidebar {
        position: absolute !important;
        float: none !important;
        display: inline-table
      }
    }
  }
</style>
